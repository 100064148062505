export default function loadScript(scriptUrl, id, scriptAttributes = {}) {
    return new Promise((resolve, reject) => {
        const existingScript = document.getElementById(id)
        if (existingScript) {
            resolve()
            return
        }
        const script = document.createElement('script')
        for (const [key, value] of Object.entries(scriptAttributes)) {
            script.setAttribute(key, value)
        }
        script.src = scriptUrl
        script.id = id
        script.onload = () => {
            resolve()
        }

        script.onerror = () =>
            reject(new Error(`Failed to load script: ${url}`))

        document.body.appendChild(script)
    })
}